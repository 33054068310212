<script setup lang="ts">
import type { SliceInterface, VoixTextFieldInterface, VoixWysiwygFieldInterface } from '@voix/types'
import { resolveFieldsArray } from '@voix/composables/useField'
import { useNoWindowScroll } from '~/composables/useNoWindowScroll'

const props = defineProps<{
  inView: boolean
  slice: SliceInterface
  fields: {
    subtitle: VoixTextFieldInterface
    title: VoixTextFieldInterface
    copy: VoixWysiwygFieldInterface

    platformIntroduction: VoixWysiwygFieldInterface
    platformSectionHeadline: VoixTextFieldInterface

    serviceSectionHeadline: VoixTextFieldInterface
    serviceIntroduction: VoixWysiwygFieldInterface
  }
}>()

defineSlice({
  name: { label: 'Venn Diagram', group: 'Venn Diagram', layouts: ['VoixMarketing'] },
  slots: [
    { name: 'platform', allowedElements: [] },
    { name: 'service', allowedElements: [] },
  ],
  description: '',
  preview: 'SlicesVennDiagram.jpg',
  fields: {
    title: {
      type: 'text',
      label: 'Title',
    },
    subtitle: {
      type: 'text',
      label: 'Subtitle',
    },
    copy: {
      type: 'wysiwyg',
      label: 'Copy',
    },
    platformSectionHeadline: {
      type: 'text',
      label: 'Platform Section Headline',
      default: 'Platform Sections',
      group: 'Platform',
    },
    platformIntroduction: {
      type: 'wysiwyg',
      label: 'Platform Introduction',
      default: `Voix is an innovative content management system designed to streamline the creation, editing, and management of digital content. Utilizing versatile "slices" written in Vue JS, Voix empowers users to effortlessly publish and customize text, images, videos, and interactive interfaces, all tailored to their brand. Additionally, its customizable nature allows Voix to become a tailored solution for any type of data, ensuring a seamless and intuitive content management experience for diverse applications.`,
      group: 'Platform',
    },

    serviceSectionHeadline: {
      type: 'text',
      label: 'Platform Section Headline',
      default: 'Service Sections',
      group: 'Service',
    },
    serviceIntroduction: {
      type: 'wysiwyg',
      label: 'Service Introduction',
      default: `Headquartered in Atlanta, GA, Logicbomb Media has been providing exceptional web technology services since 2007. Our dedication to building quality web solutions and offering immaculate service fosters strong, lasting relationships that drive continued partnership, trust, and success. Discover how we achieve this in the detailed panel below.`,
      group: 'Service',
    },
  },
  templates: [{
    label: 'Main Example',
    fields: {

    },
  }],
})

const selection: Ref<null | 'service' | 'platform'> = ref(null)
const isSelection = computed(() => selection.value !== null)

useNoWindowScroll(isSelection)

function select(value: 'service' | 'platform') {
  selection.value = value
}

const sections: any = computed(() => {
  const childSlices: any = []

  if (props.slice.elements) {
    props.slice.elements.forEach((childSlice) => {
      if (childSlice.type !== 'slice' || (childSlice.slot !== selection.value))
        return

      const fields = resolveFieldsArray(childSlice as SliceInterface)
      childSlices.push(fields)
    })
  }

  return childSlices
})

// This is the current subslice from the platform slot being displayed
const platformSection = ref(1)
// This is the current subslice from service platform slot being displayed
const serviceSection = ref(1)

const currentSection = computed(() => {
  if (selection.value === null)
    return null

  if (selection.value === 'service')
    return sections.value[serviceSection.value - 1]

  return sections.value[platformSection.value - 1]
})

// When the user clicks on the close button, reset the selection and section to the default
function resetSelection() {
  selection.value = null
  platformSection.value = 1
  serviceSection.value = 1
}

// Set the section of the current slot to display
function setSection(value: number) {
  if (selection.value === 'platform')
    platformSection.value = value
  if (selection.value === 'service')
    serviceSection.value = value
}

function nextSection() {
  if (selection.value === 'platform') {
    if (platformSection.value === sections.value.length)
      platformSection.value = 1
    else
      platformSection.value++
  }
  else {
    if (serviceSection.value === sections.value.length)
      serviceSection.value = 1
    else
      serviceSection.value++
  }
}
</script>

<template>
  <div>
    <div class="group/section relative overflow-hidden">
      <div
        class="relative z-10 container mx-auto px-8 md:px-0 py-32 duration-500" :class="{
          'opacity-0 hidden lg:block': selection !== null,
        }"
      >
        <div class="sm:text-center mx-auto sm:max-w-2xl pb-4">
          <h1
            class="heading-1 text-voix-stroke duration-1000" :class="{
              'translate-y-full opacity-0': !inView,
            }"
          >
            <span class="subtitle text-zinc-50">
              {{ fields.subtitle.value }}
            </span>
            {{ fields.title.value }}
          </h1>
          <VoixWysiwyg
            class="body duration-1000" :field="fields.copy" :class="{
              'translate-y-full opacity-0': !inView,
            }"
          />
        </div>
        <div class="group max-w-5xl flex mx-auto translate-y-1/2 lg:translate-y-0">
          <div
            class="relative w-1/2 translate-x-[10%] group-hover:translate-x-[20%] duration-300" :class="{
              'pointer-events-none opacity-0': selection !== null,
            }"
          >
            <div
              class="cursor-pointer relative rounded-full aspect-square border-4 border-voix-middle bg-voix-middle/10 flex items-center justify-center"
            >
              <div class="absolute z-20 inset-0 overflow-hidden rounded-full">
                <div
                  class="absolute inset-0 overflow-hidden circle-1 translate-x-[81%] group-hover:translate-x-[61%] duration-300 rounded-full aspect-square flex items-center justify-center"
                >
                  <video
                    v-if="inView"
                    autoplay loop muted playsinline
                    class="object-cover w-full h-full opacity-20 group-hover:opacity-100 -translate-x-[200px] group-hover:-translate-x-[150px] duration-300"
                  >
                    <source src="/videos/portal-energy.mp4" type="video/mp4">
                  </video>
                </div>
              </div>
              <a
                class="block p-8 text-center absolute z-30 bottom-0 left-0 text-2xl w-32 h-32 md:w-48 md:h-48 font-bold uppercase bg-voix-middle px-5 text-white rounded-full duration-1000 ease-out"
                :class="{
                  'translate-y-1/2': !inView,
                }"
                href="#service-section"
              >
                <div class="pt-3 md:pt-5 pb-3 text-sm md:text-2xl">
                  Service
                </div>
                <div class="font-sans text-2xs border-t border-white/20 text-white/70 md:pt-2 md:py-3">
                  learn more
                </div>
              </a>
            </div>
          </div>
          <div
            class="relative w-1/2 -translate-x-[10%] group-hover:-translate-x-[20%]  duration-300" :class="{
              'opacity-0 pointer-events-none': selection !== null,
            }"
          >
            <div
              class="cursor-pointer relative inset-0 mix-blend-overlay rounded-full aspect-square border-4 border-voix-stroke bg-voix-stroke/10 flex items-center justify-center"
            >
              <a
                class="block p-8 text-center absolute z-30 top-0 right-0 text-2xl w-32 h-32 md:w-48 md:h-48 font-bold uppercase bg-voix-stroke text-black rounded-full duration-1000 ease-out"
                :class="{
                  '-translate-y-1/2': !inView,
                }"
                href="#platform-section"
              >
                <div class="pt-3 md:pt-5 pb-3 text-sm md:text-2xl">
                  Platform
                </div>
                <div class="font-sans text-2xs border-t border-black/20 text-black/70 md:pt-2 md:py-3 leading-3">
                  learn more
                </div>
              </a>
              <div class="absolute inset-0 overflow-hidden rounded-full">
                <div
                  class="absolute inset-0 overflow-hidden circle-2 -translate-x-[81%] group-hover:-translate-x-[61%] duration-300 rounded-full aspect-square flex items-center justify-center"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class=" scale-150 md:scale-100 opacity-20 md:opacity-100 -translate-y-[40vw] md:translate-y-0 relative md:absolute inset-0 ">
        <div class="absolute inset-0 flex justify-center items-center pointer-events-none">
          <div
            class="w-[75%]  group-hover/section:w-[70%] duration-1000 ease-out aspect-square border-voix-stroke/50 group-hover/section:border-voix-stroke border rounded-full bg-gradient-to-r from-voix-middle/20 via-black/0 to-voix-stroke/15"
          />
        </div>
        <div class="absolute inset-0 flex justify-center items-center pointer-events-none">
          <div
            class="w-[80%] group-hover/section:w-[72%] duration-1000 ease-out aspect-square border-voix-stroke/50 border-2 rounded-full"
          />
        </div>
        <div class="absolute inset-0 flex justify-center items-center pointer-events-none">
          <div
            class="w-[85%] group-hover/section:w-[74%] duration-1000 ease-out aspect-square border-voix-stroke/50 group-hover/section:border-voix-stroke/30 border-4 rounded-full"
          />
        </div>
        <div class="absolute inset-0 bg-gradient-to-b from-neutral-950 via-black/0 to-neutral-950 pointer-events-none" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.group {
  &:hover {
    .circle-1 {
      box-shadow: -10px 0px 80px #3805F5;
    }

    .circle-2 {
      box-shadow: 10px 0px 50px #FFFD5A;
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.crosspush-enter-active,
.crosspush-leave-active {
  @apply relative duration-500;

  &::after {
    content: '';
    @apply absolute left-0 right-0 bottom-0 h-0 -mx-20 -mb-16 duration-500;
  }
}

.service {
  .crosspush-enter-active,
  .crosspush-leave-active {
    &::after {
      content: '';
      @apply bg-white;
    }
  }
}

.platform {
  .crosspush-enter-active,
  .crosspush-leave-active {
    &::after {
      content: '';
      @apply bg-neutral-950;
    }
  }
}

.crosspush-enter-from,
.crosspush-leave-to {
  &::after {
    @apply h-[500px];
  }
}
</style>
