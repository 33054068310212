import { useScrollLock } from '@vueuse/core'

export function useNoWindowScroll(watcher: Ref<boolean>) {
  if (typeof window === 'undefined')
    return

  const el = ref<HTMLElement | null>(null)
  const isLocked = useScrollLock(el)

  el.value = document.querySelector('html')
  isLocked.value = false

  watchEffect(() => {
    if (watcher.value)
      isLocked.value = true
    else
      isLocked.value = false
  })
}
